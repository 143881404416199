.indent {
/*    display: block;
*/    padding-left: 50px;
}

.paragraph {
/*    display: block;
*/    padding-left: 30px;
}

ul {
/*    display: block;
*/    padding-left: 100px !important; 
}

